import React from 'react'
import Testimonials from './Testimonials'
export default function MyTestimonials() {
  return (
    <div>
      <div className='padding ' style={{paddingTop:"10vh"}}> </div>
      <Testimonials/>
    </div>
  )
}

import React from 'react'
import Mystudents from './Mystudents'
export default function Placements() {
  return (
    <div>
      <div className='padding' style={{paddingTop:"10vh"}}></div>
      <Mystudents/>
    </div>
  )
}

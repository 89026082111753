import React from 'react'
import './mystudents.css'
import './myStudents2.css'
export default function Mystudents() {
  return (
    <>
    
<div className='my-students'>
    <diV className="gallaryBox page pages" id="gallary">
      <div className='gin'>
        <div className='gallary'>
          <h3 className='text-blk heading' style={{fontSize:"30px",letterSpacing:"-.5px"}}>OUR STUDENTS ARE WORKING IN</h3>
          <div className="slider">
	<div className="slide-track">
		<div className="myslide">
			<img className="img" src="https://media.mpcfilm.com/app/uploads/2022/09/Untitled-1-1-640x960.jpg" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLq9e1sk6JUWPtCsrqNSjUu_9-BC2YNscVnA&usqp=CAU" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://media.mpcfilm.com/app/uploads/2022/12/MicrosoftTeams-image-123.jpg" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://images.unsplash.com/photo-1586521995568-39abaa0c2311?ixlib=rb-1.2.1" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYu9YYDYEb-TaTNY7lWR3-zc5QDxsM_T8hle0AP3XMew&s" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://www.shutterstock.com/shutterstock/videos/1105591315/thumb/10.jpg?ip=x480" height="130" width="250" alt="" />
		</div>
		{/* <div className="myslide">
			<img className="img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png" height="130" width="250" alt="" />
		</div> */}
		<div className="myslide">
			<img className="img" src="https://cdn.worldvectorlogo.com/logos/invision-studio-2.svg" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png" height="130" width="250" alt="" />
		</div>
		<div className="myslide">
			<img className="img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png" height="130" width="250" alt="" />
		</div>
	</div>
</div>
          <section id="toggle-overflow" style={{ display: "none" }}>
            <h2>Overflow</h2>
            <input id="toggle" type="checkbox" />
            <label htmlFor="toggle">
              <p id="on">ON</p>
              <p id="off">OFF</p>
            </label>
          </section>
        </div>
      </div>
    </diV>
  </div>
    </>
      )
}

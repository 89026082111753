import React from 'react'
import Banner from '../Components/Banner.js'
import Chatbot from '../Components/Chatbot'
export default function LandingPage() {
  return (
    <>
    <Banner/>
    <Chatbot/>
    </>
  )
}

import React from 'react'
import StudentGallary from '../Components/StudentGallary'
export default function MyGaller() {
  return (
    <div>
        <div className='padding' style={{paddingTop:"10vh"}}></div>
      <StudentGallary/>
    </div>
  )
}

import React,{useEffect,useContext} from 'react'
import './navbar3.css'
import context from '../context/useContext';
import image from '../Images/mantrickstudios_logo.webp';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert/Alert';
import { Link } from 'react-router-dom';
import { useRef} from 'react';
import { First } from 'react-bootstrap/esm/PageItem';
export default function Navbar3() {
    const first=useRef();
    const {gpt,setGpt}=useContext(context);
    const {openSnackbar,setOpenSnackbar,snackbarMessage,snackbarSeverity,setSnackbarSeverity,showSnackbar,setSnackbarMessage}=useContext(context);
    const navanimation=(e)=>{
        // e.target.style.top="10px";
        var mark=document.querySelector("#marker");
        mark.style.left=document.querySelector("#first").offsetLeft+"px";
        mark.style.width=document.querySelector("#first").offsetWidth+"px";
    }
    useEffect(()=>{
        
        var mark=document.querySelector("#marker");
        mark.style.left=document.querySelector('#first').offsetLeft+"px";
        mark.style.width=document.querySelector('#first').offsetWidth+"px";
    })
    useEffect(()=>{
        setTimeout(()=>{
            var mark=document.querySelector("#marker");
            mark.style.left=document.querySelector('#first').offsetLeft+"px";
            mark.style.width=document.querySelector('#first').offsetWidth+"px";
        },[1000])
        window.addEventListener('scroll', function() {
            if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
                document.querySelector('.nav').classList.add('affix');
                console.log("OK");
            } else {
                document.querySelector('.nav').classList.remove('affix');
            }
            });
            const navTrigger = document.querySelector('.navTrigger');
      navTrigger.addEventListener('click', function() {
          this.classList.toggle('active');
          const mainListDiv = document.getElementById('mainListDiv');
          mainListDiv.classList.toggle('show_list');
          fadeIn(mainListDiv);
      });
    },[])
    function fadeIn(element) {
        let opacity = 1;
        const links=document.querySelector('.ulm');
      links.style.display="none";
      setTimeout(()=>{
          links.style.display="block";
      },500)
      element.style.display = 'block';
      const fadeInInterval = setInterval(function() {
          if (opacity < 1) {
              opacity += 0.5;
              element.style.opacity = opacity;
            } else {
                clearInterval(fadeInInterval);
            }
        }, 50);
    }
   
    const marker=(e)=>{
        if(window.innerWidth<=1048)
        {
            const navTrigger = document.querySelector('.navTrigger').classList.toggle('active');
            const mainListDiv = document.getElementById('mainListDiv');
            mainListDiv.classList.toggle('show_list');
            fadeIn(mainListDiv);
        }

        var mark=document.querySelector("#marker");
        mark.style.left=e.target.offsetLeft+"px";
        mark.style.width=e.target.offsetWidth+"px";
        
    }
  return (
    <>
    <div className='navUp'>
        <ul style={{    margin:"auto 2px"}}>
            <li className='ul-1'>
                <span>
                <span class="material-symbols-outlined" style={{color:"red"}}>
                    call
                </span>
                
                +91 7993338242
                </span>
            </li>
            <li className='ul-2'>
                <span>
                <span class="material-symbols-outlined">
                    mail
                    </span>
                    <a style={{textDecoration:"none",color:"black"}} href = "mailto:training@mantrickstudios.com">info@mantrickstudios.com
</a>
                    
                </span>
            </li>
            <li className='ul-3'>
                <span>
                <span class="material-symbols-outlined">
                    schedule
                </span>
                Mon-Sat 09.00 AM to 07.00 PM
                </span>
            </li>
                    <li className='ul-3' style={{cursor:"pointer"}} id='sh-meet'><a  className=''  onClick={(e)=>{
                        document.querySelector(".back-2").style.display="flex";
                        if(window.innerWidth<=1000)
                        {
                            document.querySelector('.navTrigger').classList.toggle('active');
            
                            const mainListDiv = document.querySelector('#mainListDiv');
                            mainListDiv.classList.toggle('show_list');
                            fadeIn(mainListDiv);
                        }
                        
                        
                        document.querySelector(".back-2").style.display="block";
                        document.querySelector(".schedule-meet").style.display="none";
                        document.querySelector(".schedule-meet-o").style.display="flex";
                    }}>
                        <span>
                        <span class="material-symbols-outlined"style={{color:"white"}}>
                            coffee
                        </span>
                    Let's meet for a coffee 
                        </span>
                    </a></li>
        </ul>
    </div>
     <nav className="nav">
        <div className="container">
            <div className="logo">
                <a href="#" ><img src={image} alt=""/></a>
            </div>
            <div id="mainListDiv" className="main_list">
                <ul className="navlinks ulm">
                    <div id='marker'></div>
                    <li>
                        <Link to="/" id='first' ref={first}  className='ul1 ul1' onClick={marker}>Home</Link>
                    </li>
                    <li><Link to="/about" className='ul1 ul2' id="#aboutUs1" onClick={marker}>About</Link></li>
                    <li><Link to="/services" className='ul1 ul3'  id="#services-head3" onClick={marker}>Services</Link></li>
                    <li><Link to="/portfolio" className='ul1 ul3' id="#team1"
                    //  onClick={portfolio}
                    onClick={marker}
                    >Portfolio</Link></li>
                    <li><Link to="/team" className='ul1 ul5'   id="#team22" onClick={marker} >Team</Link></li>
                    <li>
                    <Link to="/academy" className="dropbtn" style={{display:"flex",alignItems:"center",justifyContent:"center"}} onClick={marker}>Academy</Link>
                    </li>
                        {/* <li>
                        <div className="dropdown">
        <Link to="/academy" className="dropbtn" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>Academy <span class="material-symbols-outlined" style={{color:"red"}}>
keyboard_arrow_down
</span></Link>
        <div className="dropdown-content">
          <Link 
           onClick={(e)=>{
            document.querySelector(".back-2").style.display="flex";
            if(window.innerWidth<=1000)
            {
                document.querySelector('.navTrigger').classList.toggle('active');
                const mainListDiv = document.querySelector('#mainListDiv');
                mainListDiv.classList.toggle('show_list');
                fadeIn(mainListDiv);
            }
            document.querySelector(".schedule-meet-o").style.display="none";
            document.querySelector(".schedule-meet").style.display="flex";
        }}
          >Brochure</Link>
          <Link to="blogs">Blogs</Link>
          <Link to="/mystudents">Placements</Link>
          <Link>
          <div class="dropdown2">
            <Link to='/studentcorner' class="nav-link2 dropdown-toggle2" style={{background:"#575757"}}>StudentCorner▼
            </Link>
            <div class="dropdown-content2">
                <Link to="/gallery">Gallery</Link>
                <Link to="/testimonials">Testimonials</Link>
                
            </div>
        </div>
          </Link>
          
        </div>
      </div>
                        </li> */}
                        <li>
        <Link to="/contact"  className="dropbtn" style={{display:"flex",alignItems:"center",justifyContent:"center"}} onClick={marker}>Reach Us</Link>
                        {/* <div className="dropdown">
        <div className="dropdown-content">
          <Link to="/">Business</Link>
          <Link to="/">Careers</Link>
        </div>
      </div> */}
                        </li>
                </ul>
            </div>
            <span className="navTrigger">
                <i></i>
                <i></i>
                <i></i>
            </span>
        </div>
    </nav>
    <div className='snacker' >
    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
    </>
  )
}

import React from 'react'
import About2 from './About2'
export default function AboutPage() {
  return (
    <div>
      <div style={{paddingTop:"10vh"}}></div>
      <About2/>
    </div>
  )
}

import React from 'react'
import ContactForm from './ContactForm'
export default function Contact() {
  return (
    <div>
    <div className='padding' style={{paddingTop:"15vh"}}>
    <h3 style={{color:"white",textAlign:"center"}}className="head-one">Reach Us</h3>
    </div>
    <ContactForm/>
    </div>
  )
}
